import { initializeApp } from 'firebase/app'; 
import { getFirestore } from 'firebase/firestore/lite'; 
import { getAuth } from "firebase/auth"; 
 
const app = initializeApp({ 
  apiKey: "AIzaSyByxmD8wuVf_lkPXVKL2xg4yEOwfvVEChM", 
  authDomain: "connect-4dee9.firebaseapp.com", 
  projectId: "connect-4dee9", 
  storageBucket: "connect-4dee9.appspot.com", 
  messagingSenderId: "1006607719234", 
  appId: "1:1006607719234:web:e4d1096c15b7daf4c486db", 
  measurementId: "G-2X47JJTVD4" 
}); 
 
getFirestore(app); 
 
const auth = getAuth(); 
 
export { 
  auth 
};